<template>
  <nav>
    <ol>
      <li v-for="(stage,index) in content" :key="index">
        <template v-if="stage.t">{{$t(stage.t)}}</template>
        <template v-else>{{stage.name}}</template>
      </li>
    </ol>
  </nav>
</template>

<script>
export default {
  props: ["content"]
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
nav {
  @extend %font-bold;
  background-color: $secondary;
  color: white;
  text-transform: uppercase;
  padding: 10px 15px;
  font-size: 20px;
  margin: 0 -15px 30px -15px;
}
ol {
  list-style-type: none;
  padding-left: 0;
  li {
    display: inline-block;
    &:not(:last-child)::after {
      display: inline-block;
      content: "/";
      padding: 0 5px;
    }
  }
}
</style>