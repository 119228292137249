<template>
  <div class="box">
    <h2>{{ xTitle }}</h2>
    <XTable
      :x-content="content"
      :x-columns="columns"
      @row-click="go"
      class="clickable"
      sort-by="Goals"
      sort-dir="desc"
    >
      <template #cell[JerseyNumber]="{cell}">{{
        cell == "0" ? "-" : cell
      }}</template>
      <template #cell[First_Name]="{row}"
        >{{ row.First_Name }} {{ row.Last_Name }}
        <p v-if="row.Coach" style="color: #b57f02; display:inline;">
          Coach
        </p></template
      >
      <template #cell[Goals]="{row}">
        <span class="icon" v-for="n in row.Goals" :key="'g' + n">&#xe800;</span>
        <span class="icon" v-for="n in row.Assists" :key="'a' + n"
          >&#xe801;</span
        >
        <span class="icon yellow" v-for="n in row.YellowCard" :key="'y' + n"
          >&#xe802;</span
        >
        <span
          class="icon yellow"
          v-for="n in row.Double_Yellow_Card"
          :key="'d' + n"
          >&#xe803;</span
        >
        <span class="icon red" v-for="n in row.RedCard" :key="'r' + n"
          >&#xe802;</span
        >
        <span
          v-if="
            row.Goals +
              row.Assists +
              row.YellowCard +
              row.Double_Yellow_Card +
              row.RedCard ==
              0
          "
          >-</span
        >
      </template>
    </XTable>
  </div>
</template>

<script>
import XTable from "./XTable.vue";
export default {
  components: {
    XTable,
  },
  props: ["content", "xTitle"],
  data() {
    return {
      columns: [
        {
          display: "#",
          name: "JerseyNumber",
          sortable: true,
          minimal: true,
          align: "center",
        },
        { t: "common.name", name: "First_Name", sortable: true, minimal: true },
        {
          t: "common.performance",
          name: "Goals",
          sortable: true,
          preferDesc: true,
        },
      ],
    };
  },
  methods: {
    go(e) {
      this.$router.push({ path: `/players/${e.Player_id}` });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/theme.scss";
.yellow {
  color: #ffd000;
}
.red {
  color: red;
}
.icon {
  line-height: 18px;
  font-size: 18px;
  //text-shadow: 0.5px 0.5px #000;
}
</style>
