var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box"},[_c('h2',[_vm._v(_vm._s(_vm.xTitle))]),_c('XTable',{staticClass:"clickable",attrs:{"x-content":_vm.content,"x-columns":_vm.columns,"sort-by":"Goals","sort-dir":"desc"},on:{"row-click":_vm.go},scopedSlots:_vm._u([{key:"cell[JerseyNumber]",fn:function({cell}){return [_vm._v(_vm._s(cell == "0" ? "-" : cell))]}},{key:"cell[First_Name]",fn:function({row}){return [_vm._v(_vm._s(row.First_Name)+" "+_vm._s(row.Last_Name)+" "),(row.Coach)?_c('p',{staticStyle:{"color":"#b57f02","display":"inline"}},[_vm._v(" Coach ")]):_vm._e()]}},{key:"cell[Goals]",fn:function({row}){return [_vm._l((row.Goals),function(n){return _c('span',{key:'g' + n,staticClass:"icon"},[_vm._v("")])}),_vm._l((row.Assists),function(n){return _c('span',{key:'a' + n,staticClass:"icon"},[_vm._v("")])}),_vm._l((row.YellowCard),function(n){return _c('span',{key:'y' + n,staticClass:"icon yellow"},[_vm._v("")])}),_vm._l((row.Double_Yellow_Card),function(n){return _c('span',{key:'d' + n,staticClass:"icon yellow"},[_vm._v("")])}),_vm._l((row.RedCard),function(n){return _c('span',{key:'r' + n,staticClass:"icon red"},[_vm._v("")])}),(
          row.Goals +
            row.Assists +
            row.YellowCard +
            row.Double_Yellow_Card +
            row.RedCard ==
            0
        )?_c('span',[_vm._v("-")]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }