<template>
  <div class="event" v-if="!page404">
    <XNav v-if="event[0]" :content="nav" />
    <Banner
      v-if="event[0]"
      :title="
        $DateTime
          .fromISO(event[0].Date)
          .setLocale($i18n.locale)
          .toFormat('MMM d')
      "
      :subtitle="event[0].SeasonName + ' - ' + event[0].LeagueName"
    />
    <div class="info" v-if="event[0]">
      <div class="team-name">
        <img :src="`${$bucketUrl}/images/teams/${event[0].HomeTeam_id}.png`" />
        <p>
          <router-link :to="`/team/${event[0].HomeTeam_id}/${event[0].League_id}`">{{
            event[0].HomeTeam_Name
          }}</router-link>
        </p>
      </div>
      <div class="data">
        <p class="score">{{ event[0].Home_Score }} - {{ event[0].Away_Score }}</p>
        <p>
          <a :href="'http://maps.google.com/?q=' + event[0].Address">{{
            event[0].Field
          }}</a>
          <br />
          {{
            $DateTime
              .fromISO(event[0].Date)
              .setLocale($i18n.locale)
              .toFormat("h:mm a")
          }}
        </p>
      </div>
      <div class="team-name">
        <img :src="`${$bucketUrl}/images/teams/${event[0].AwayTeam_id}.png`" />
        <p>
          <router-link :to="`/team/${event[0].AwayTeam_id}/${event[0].League_id}`">{{
            event[0].AwayTeam_Name
          }}</router-link>
        </p>
      </div>
    </div>
    <button class="share-button" v-if="sharable" @click="share">
      <span class="icon">&#xe804;</span>{{ $t("common.shareThisPage") }}
    </button>
    <div class="tables" v-if="event[0]">
      <EventTable :x-title="event[0].HomeTeam_Name" :content="event[0].homeTeamResults" />
      <EventTable :x-title="event[0].AwayTeam_Name" :content="event[0].awayTeamResults" />
    </div>
  </div>
  <Page404 v-else />
</template>

<script>
import axios from "axios";
import EventTable from "@/components/tables/EventTable.vue";
import XNav from "@/components/XNav.vue";
import Banner from "@/components/Banner.vue";
import Page404 from "@/views/404.vue";
export default {
  components: {
    EventTable,
    XNav,
    Banner,
    Page404,
  },
  data() {
    return {
      event: [],
      sharable: !!navigator.share,
      page404: false,
    };
  },
  mounted() {
    axios
      .get(`${this.$apiUrl}/events/${this.$route.params.id}`)
      .then((res) => {
        this.event = res.data;
      })
      .then(() => this.$store.dispatch("pageLoad", false))
      .catch(() => {
        this.page404 = true;
        this.$store.dispatch("pageLoad", false);
      });
  },
  methods: {
    share() {
      navigator.share({
        title: this.event[0].HomeTeam_Name + " vs " + this.event[0].AwayTeam_Name,
        url: window.location.href,
      });
    },
  },
  computed: {
    nav() {
      if (!this.event[0]) return [{ name: "Events" }];

      return [
        { t: "common.leagues" },
        { name: this.event[0].LeagueName },
        {
          name:
            this.$DateTime
              .fromISO(this.event[0].Date)
              .setLocale(this.$i18n.locale)
              .toFormat("MMM d") +
            " - " +
            this.event[0].HomeTeam_Name +
            " vs " +
            this.event[0].AwayTeam_Name,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.event {
  padding-top: 15px;
}
.info {
  @extend %light-pattern;
  margin-left: 0;
  margin-right: 0;
  display: flex;
  position: relative;
}
.team-name {
  font-size: 24px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  img {
    height: 170px;
    margin: 15px;
    //object-fit: cover;
    //object-position: center center;
  }
  p {
    margin: 15px;
    text-align: center;
    a {
      color: black;
      text-decoration: none;
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
.data {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  padding-top: 15px;
  a {
    color: black;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.score {
  @extend %font-bold;
  font-size: 60px;
}
h2 {
  background-color: $primary;
  font-size: 1.7em;
  padding: 0.1em 1em;
  color: white;
  font-weight: 200;
  margin-top: 15px;
}
th {
  text-align: right;
}
.logo {
  margin-bottom: -1.2em;
  height: 3em;
  width: 3em;
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  margin-left: 15px;
  margin-right: 15px;
}
.tables {
  display: flex;
  margin: 0 -15px;
  align-items: stretch;
  & > div {
    flex: 1 1;
    margin: 30px 15px;
  }
}
.share-button {
  margin: 15px 0;
  font-size: 20px;
  height: 50px;
  width: 100%;
  padding: 10px;
  background-color: $secondary;
  border: none;
  color: white;
}
@media only screen and (max-width: $mobile-breakpoint) {
  .tables {
    flex-direction: column;
  }
  .score {
    font-size: 50px;
  }
  .team-name {
    img {
      height: 100px;
    }
  }
}
</style>
