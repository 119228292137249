<template>
  <div class="page">
    <h1>Oops</h1>
    <h1>Page not found.</h1>
    <h1>:(</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
#app > .page {
  padding-top: 30px;
}
h1 {
  @extend %font-bold;
  text-align: center;
  font-size: 50px;
}
</style>