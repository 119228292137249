<template>
  <div class="banner">
    <div class="banner-text">
      <h1>{{title}}</h1>
      <hr />
      <p>{{subtitle}}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "subtitle"]
};
</script>

<style lang="scss" scoped>
@import "@/theme.scss";
.banner {
  @extend %secondary-pattern;
  border-bottom: 10px solid $primary;
  margin-top:15px;
}
.banner-text {
  color: white;
  text-align: center;
  padding: 15px;
  background-size: 140px auto;
  background-repeat: no-repeat;
  background-position: left -20px center;
  text-transform: uppercase;
  h1 {
    font-size: 40px;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
  }
  hr {
    width: 200px;
    border-top: 1px solid white;
    margin: 0 auto;
  }
}
</style>